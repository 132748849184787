import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import HowItWorksPage from '../ui/pages/HowItWorksPage'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/de.json'

const HowItWorks = props => (
  <>
    <HelmetComponent
      title={metaTags.howItworksTitle}
      description={metaTags.howItWorks}
      page="de/how-it-works"
    />
    <AppWrapper {...props} lang="de">
      <HowItWorksPage />
    </AppWrapper>
  </>
)

export default HowItWorks
